import React from 'react'

const Tools = () => {
  return (
    <div className="sklls__content">
        <h3 className="skills__titlle">Development Tools</h3>

        <div className="skills__box">
            <div className="skills__group">
                <div className="skills__data">
                <i class='bx bx-check-circle'></i>

                <div>
                    <h3 className="skills__name">Microsoft Office</h3>
                </div>

                </div>

                <div className="skills__data">
                <i class='bx bx-check-circle'></i>

                <div>
                    <h3 className="skills__name">VS Code</h3>
                </div>

                </div>

                <div className="skills__data">
                <i class='bx bx-check-circle'></i>

                <div>
                    <h3 className="skills__name">IntelliJ</h3>
                </div>

                </div>

                <div className="skills__data">
                <i class='bx bx-check-circle'></i>

                <div>
                    <h3 className="skills__name">Atlassian Jira</h3>
                </div>

                </div>

                <div className="skills__data">
                <i class='bx bx-check-circle'></i>

                <div>
                    <h3 className="skills__name">AWS</h3>
                </div>

                </div>
            </div>

            <div className="skills__group">
                <div className="skills__data">
                <i class='bx bx-check-circle'></i>

                <div>
                    <h3 className="skills__name">Vim</h3>
                </div>

                </div>

                <div className="skills__data">
                <i class='bx bx-check-circle'></i>

                <div>
                    <h3 className="skills__name">Git/Github</h3>
                </div>

                </div>

                <div className="skills__data">
                <i class='bx bx-check-circle'></i>

                <div>
                    <h3 className="skills__name">Figma</h3>
                </div>

                </div>

                <div className="skills__data">
                <i class='bx bx-check-circle'></i>

                <div>
                    <h3 className="skills__name">PowerBI</h3>
                </div>

                </div>

                <div className="skills__data">
                <i class='bx bx-check-circle'></i>

                <div>
                    <h3 className="skills__name">Adobe Creative Cloud</h3>
                </div>

                </div>
            </div>
        </div>
    </div>
  )
}

export default Tools
